/**
 * Enable deep links for iOS and Android 
 * according to https://capacitorjs.com/docs/guides/deep-links
 */
import { App, URLOpenListenerEvent } from '@capacitor/app'

export default defineNuxtPlugin(() => {
  console.debug('Add appUrlOpen listener')

  App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
    const url = new URL(event.url)
    const slug = url.pathname + url.search
  
    console.debug('Event appUrlOpen received', event)
    
    if (slug) {
      console.debug(`plugin:deepLinks routing to ${slug}`, event)

      return navigateTo(slug)
    }
  })
})
