export const enum FilterOptions {
  all = 0,
  myRolls = 1,
  joined = 2,
}

export enum CameraRollDialogState {
  Closed = 0,
  SelectTask = 1,
  InviteQRCode = 2,
}

export enum UserStates {
  AnonymousGuest = 'anonymousGuest',
  NamedGuest = 'namedGuest',
  RecognizableGuest = 'recognizableGuest',
  Registered = 'registered',
}

/*export enum Color {
    red = 1,
    blue = 2
  }*/
