import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { useUserStore } from '~~/store/user'

export default defineNuxtRouteMiddleware(async (to, from) => {
  try {
    const userStore = useUserStore()
    const currentUser = await userStore.getCurrentUser()

    if (!currentUser && to.meta.accesscontrol !== 'public') {
      console.debug('auth-triggers: User not logged in, redirecting to /login')
      return navigateTo('/start')
    }

    // this is disabled because it sometimes causes an infinite loop
    if (to.meta.accesscontrol !== 'public') {
      userStore.ensureStateInSync()
      if (currentUser) {
        const userId = currentUser.email || currentUser.uid
        console.debug(`auth-triggers: User logged in, setting user id for analytics ${userId}`)
        await FirebaseAnalytics.setUserId({
          userId,
        })
      }
    }
  } catch (error) {
    console.error('auth-triggers: Error getting current user', error)
    return navigateTo('/start')
  }
})
