import {collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where,} from '@firebase/firestore'
import {FirebaseAuthentication} from '@capacitor-firebase/authentication'

import { defineStore } from 'pinia'
import { Locales, User } from '~~/types/types'
import { UserStates } from '~~/types/enums'
import { deleteUser, getAuth, onAuthStateChanged } from '@firebase/auth'
import { Capacitor } from '@capacitor/core'
import { Buffer } from 'buffer';
import { isEqual } from 'lodash'
import useAuth from '~/composables/useAuth'

const baseLocale = 'en'

export const useUserStore = defineStore({
  id: 'user',
  state: () => {
    return {
      user: {
        uid: '',
        email: '',
        displayName: '',
        photoURL: null,
        locale: baseLocale,
        hasPassword: false,
        emailVerified: false,
      } as User,
      userRef: null as any,
      firebaseUser: null as null | FirebaseUser,
      snapShotListener: [] as Unsubscribe[],
      localeChangeListener: null as Function | null,
      justLoggedIn: false, // used in "ensureStateInSync" to let system know that we just logged in and it's okay if state is not in sync
    }
  },
  actions: {
    async setup(callback?: Function | null) {
      console.debug('UserStore:setup')
      useAuth()
      if (this.isIOSDevice) {
        getAuth().onAuthStateChanged(async (u) => {
          console.debug('UserStore:onAuthStateChanged getAuth', u)
          await this.refreshState(u)
        })
      } else {
        await FirebaseAuthentication.removeAllListeners()

        FirebaseAuthentication.addListener('authStateChange', async (u) => {
          console.debug('UserStore:onAuthStateChanged FirebaseAuthentication', u)
          await this.refreshState(u)
        })
      }

    },
    async refreshState(u, params = {}) {
      if (!u) {
        console.debug('UserStore: authStateChange did not provide a user')
        return
      }
      const user = this.isIOSDevice ? u : u?.user
      if (user) {
        this.$patch((state) => (state['firebaseUser'] = user))
        const store = useNuxtApp().$db
        const ref = doc(store, 'users', user.uid)
        this.$patch((state) => (state['userRef'] = ref))
        let initialState = {
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          displayName: user?.displayName || '',
          photoURL: user?.photoUrl || '',
          locale: user?.locale || baseLocale,
        }
        if (params) {
          initialState = { ...initialState, ...params };
        }
        this.$patch((state) => (state['user'] = {
          ...state['user'],
          ...initialState,
        }));

        // The race condition note remains the same.
        // Using the useful parts of this user here as well.
        const loadUser = async () => {
          this.justLoggedIn = false
          const docSnap = await getDoc(ref)
          if (docSnap.exists()) {
            const data = docSnap.data()
            const loadedUser = {
              uid: initialState.uid,
              email: initialState.email,
              displayName: data.displayName ? data.displayName : (initialState?.displayName ? initialState?.displayName : null),
              photoURL: data.photoURL ? data.photoURL : (initialState?.photoURL ? initialState?.photoURL : null),
              locale: data.locale || baseLocale,
              hasPassword: data.hasPassword || false,
              emailVerified: initialState.emailVerified,
            };
            if (!isEqual(data, loadedUser) && ref) {
              await setDoc(ref, loadedUser, {merge: true})
            }
            this.$patch((state) => (state['user'] = loadedUser))
            if (this.localeChangeListener) {
              this.localeChangeListener(loadedUser.locale)
            }
            return loadedUser
          } else {
            return false
          }
        };

        const loadedUser = await loadUser()
        if (loadedUser && loadedUser.uid) {
          console.debug('UserStore: user loaded immediately')
        } else {
          console.debug('UserStore: user not loaded immediately, trying again in 5 seconds')
          let retryCounter = 0
          const interval = window.setInterval(async () => {
            await loadUser()
            if (this.user.uid) {
              window.clearInterval(interval)
              console.debug(`UserStore: user ${this.user.uid} loaded with delay`)
            }
            if (retryCounter > 10) {
              window.clearInterval(interval)
              throw new Error(`UserStore: user not loaded after 5 retries`)
            }
            retryCounter++
          }, 1000)
        }
      } else {
        console.debug('UserStore: authStateChange did not provide a valid user, resetting state')
        // Consider whether you want to log out here or just navigate.
        const router = useNuxtApp().$router
        if (router.currentRoute.value.meta.accesscontrol !== 'public') {
          router.push('/register')
        }
      }
    },
    ensureStateInSync() {
      console.debug('UserStore:ensureStateInSync')
      if (!this.firebaseUser && !this.justLoggedIn) {
        if (this.isIOSDevice) {
          onAuthStateChanged(getAuth(), async (u) => {
            try {
              await this.refreshState(u)
            } catch (e) {
              console.error('Could not refresh state - getAuth')
              await this.logout()
            }
          })
        } else {
          FirebaseAuthentication.getCurrentUser().then(async (u) => {
            try {
              await this.refreshState(u)
            } catch (e) {
              console.error('Could not refresh state - FirebaseAuthentication')
              await this.logout()
            }
          })
        }
      }
    },
    async logout() {
      this.snapShotListener.forEach((unssubscribe) => {
        unssubscribe()
      })
      await FirebaseAuthentication.signOut()
      if (Capacitor.isNativePlatform()) {
        // Sign in also on the web layer
        const auth = getAuth()
        await auth.signOut()
        console.debug('Logout on Web successful')
      }
      this.$reset()
    },
    addUnsusbscribeSnapshotListener(listener: Function | null) {
      this.snapShotListener.push(listener)
    },
    async homepage(): Promise<string> {
      const userRef = this.userRef
      if (!userRef) {
        return '/'
      }
      const user = await getDoc(userRef)
      if (user.data()) {
        const userData = user.data() as User
        if (!userData.hasPassword) {
          // this might be a code repetition
          const store = useNuxtApp().$db
          const collectionRef = collection(store, 'camera-rolls')
          const q = query(collectionRef, where('members', 'array-contains', userRef))
          const docsSnapshot = await getDocs(q)
          if (docsSnapshot.docs.length === 1) {
            console.debug('Redirecting to the one camera-roll where the user has access to')
            return `/camera-roll/${docsSnapshot.docs[0].id}`
          }
        }
      }
      return `/camera-rolls`
    },
    async updateName(name: string) {
      const userRef = this.userRef
      const user = await getDoc(userRef)
      const userData = user.data() as User
      if (userData) {
        userData.displayName = name
        this.$patch((state) => (state.user.displayName = userData.displayName))
        await setDoc(userRef, userData, { merge: true })
      }
      return userRef
    },
    async updateNameEmail(name: string, email?: string) {
      const userRef = this.userRef
      const user = await getDoc(userRef)
      if (user.data()) {
        const userData = user.data() as User
        if (userData) {
          // update in Firestore
          userData.displayName = name
          this.$patch((state) => (state.user.displayName = userData.displayName))
          if (email) {
            this.$patch((state) => (state.user.email = email.toLowerCase()))
            await FirebaseAuthentication.updateEmail({
              newEmail: email,
            })
          }
          await setDoc(userRef, userData, { merge: true })

          if (email) {
            const store = useNuxtApp().$db
            // @tsignore
            const publicRef = doc(store, 'users-public', this.user.uid)
            const msgBuffer = new TextEncoder().encode(email.toLowerCase())
            const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
            await setDoc(publicRef, { email: Buffer.from(hashBuffer).toString("base64") }, { merge: true })
            console.debug('local store updated')
          }
        }
      }
      return userRef
    },
    async updateAvatar(url: string) {
      await updateDoc(this.userRef, { photoURL: url })
      this.$patch((state) => (state.user.photoURL = url))
    },
    // Marks the user as guest.
    async setUserToGuest(isGuest: boolean) {
      let userRef = this.userRef
      if (!userRef) {
        this.setup(async () => {
          const store = useNuxtApp().$db
          if (this.firebaseUser) {
            // @todo Code duplication, needs to be fixed
            userRef = doc(store, 'users', this.firebaseUser.uid)
            await setDoc(userRef, { hasPassword: !isGuest }, { merge: true })
            console.debug(`hasPassword set to ${!isGuest} in user ${userRef.id}.`)
            this.$patch((state) => (state.user.hasPassword = !isGuest))
          } else {
            throw new Error('No firebase user retrieable via userStore')
          }
        })
      }
      await setDoc(userRef, { hasPassword: !isGuest }, { merge: true })
      console.debug(`hasPassword set to ${!isGuest} in user ${userRef.id}.`)
      this.$patch((state) => (state.user.hasPassword = !isGuest))
    },
    async deleteCurrentUser() {
      const user = this.firebaseUser
      if (user) {
        await deleteDoc(this.userRef)

        const auth = getAuth()
        deleteUser(auth.currentUser!)
          .then(() => {
            console.debug('FirebaseAuth User deleted')
          })
          .catch((error) => {
            console.error('FirebaseAuth User deletion failed', error)
          })

        // @todo Remove user fromall camera-rolls where he is a member.
        // Delete his photos

        await this.logout()
        console.debug('User deleted')
      } else {
        console.debug('User not deleted')
      }
    },
    // @todo Remove this if not used
    async isLoggedIn() {
      return true
    },
    async getCurrentUser() {
      /* For iOS devices, the current user must be taken from the Firebase SDK */
      if (this.isIOSDevice) {
        return getAuth().currentUser
      }
      const currentUser = await FirebaseAuthentication.getCurrentUser()
      return currentUser.user
    },
    async setLocale(locale: string) {
      console.debug(`UserStore: setLocale ${locale}`)

      let userRef = this.userRef
      this.$patch((state) => (state.user.locale = locale as Locales))
      await setDoc(userRef, { locale: locale as Locales }, { merge: true })
    },
    subscribeToLocaleChange(f: any) {
      console.debug('UserStore: subscribeToLocaleChange')
      this.$patch((state) => (state.localeChangeListener = f))
    }
  },
  getters: {
    userState(state): UserStates | null {
      console.debug('calling getter')
      const { user, firebaseUser } = state

      if (!user || !user.uid || !firebaseUser) return null

      const isAnonymous = firebaseUser.isAnonymous || (firebaseUser.user && firebaseUser.user.isAnonymous)
      if (isAnonymous) {
        return user.displayName ? UserStates.NamedGuest : UserStates.AnonymousGuest
      }
      return UserStates.Registered
    },
    isGuest(state): boolean {
      return state.userState === UserStates.NamedGuest || state.userState === UserStates.RecognizableGuest
    },
    isIOSDevice(): boolean {
      return Capacitor.getPlatform() == 'ios'
    }
  },
})
