/**
 * Register global components
 */
import { Icon } from "@iconify/vue";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VueLazyLoad from 'vue3-lazyload'

const components = { Icon };

const options: PluginOptions = {
  timeout: 3000,
  closeButton: false,
  hideProgressBar: true,
  position: POSITION.BOTTOM_CENTER,
  containerClassName: "custom-container-class",
  toastClassName: "custom-toast-class",
}

export default defineNuxtPlugin(({ vueApp }) => {
  Object.entries(components).forEach(([name, component]) => {
    vueApp.component(name, component);
  });

  vueApp.use(Toast, options)
  vueApp.use(LoadingPlugin);
  vueApp.use(VueLazyLoad)
});
