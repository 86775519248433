/**
 * Solves the issue describe in https://stackoverflow.com/a/72044685/2454815 
 */
import { Capacitor } from "@capacitor/core";
import { getApp } from "firebase/app";
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from "firebase/auth";

export default () => {
    let auth: Auth;
    const app = getApp()
    if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
        console.debug("Auth initialized with indexedDBLocalPersistence")
    } else {
        auth = getAuth(app);
    }
    return auth
}
