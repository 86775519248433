import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoCheckmark, LazySvgoLogoStone300, LazySvgoLongarrow, LazySvgoPcLogoCircled } from '#components'
const lazyGlobalComponents = [
  ["SvgoCheckmark", LazySvgoCheckmark],
["SvgoLogoStone300", LazySvgoLogoStone300],
["SvgoLongarrow", LazySvgoLongarrow],
["SvgoPcLogoCircled", LazySvgoPcLogoCircled]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
