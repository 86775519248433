import * as Sentry from "@sentry/vue";


export default defineNuxtPlugin(async ({ vueApp }) => {
    const router = useRouter()
    const config = useRuntimeConfig()
    if (config.public.sentryDsn) {
        console.debug("Enabling Sentry")
        Sentry.init({
            app: vueApp,
            dsn: config.public.sentryDsn,
            environment: config.public.sentryEnv,
            tracesSampleRate: 1.0
        })
    }    
})
