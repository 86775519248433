import { defineNuxtPlugin } from '#app'
import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { useUserStore } from '~~/store/user'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { getAnalytics } from 'firebase/analytics'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  console.debug('Initializing Firebase', config.public)
  const firebaseApp = initializeApp(config.public)
  if (config.public.useEmulators) {
    // See https://stackoverflow.com/a/73854687/2454815
    initializeFirestore(firebaseApp, { experimentalForceLongPolling: true })
  }
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp)
  const functions = getFunctions(firebaseApp)
  const analytics = getAnalytics(firebaseApp)

  if (config.public.useEmulators) {
    console.debug(`Using Firebase Emulators. Host: ${config.public.emulatorHost}`)
    connectFirestoreEmulator(db, config.public.emulatorHost, 8080)

    FirebaseAuthentication.useEmulator({
      host: 'http://' + config.public.emulatorHost,
      port: 9099,
    })
    connectStorageEmulator(storage, config.public.emulatorHost, 9199)
    connectFunctionsEmulator(functions, config.public.emulatorHost, 5001)
  } else {
    console.debug('Using Live Db')
  }

  useUserStore().setup()

  nuxtApp.provide('db', db)
  nuxtApp.provide('storage', storage)
  nuxtApp.provide('analytics', analytics)
})
