import { default as _91id_93AY0Xkf7wEcMeta } from "/builds/mpom/picture-club/pages/camera-roll/[id].vue?macro=true";
import { default as camera_45rollsz13mQR48myMeta } from "/builds/mpom/picture-club/pages/camera-rolls.vue?macro=true";
import { default as confirmation_45successBYCGU7MX7xMeta } from "/builds/mpom/picture-club/pages/confirmation-success.vue?macro=true";
import { default as about2BXZC7DPQUMeta } from "/builds/mpom/picture-club/pages/dev/about.vue?macro=true";
import { default as democdZBOKojfJMeta } from "/builds/mpom/picture-club/pages/dev/demo.vue?macro=true";
import { default as emailtemplatei8yj5TU5IkMeta } from "/builds/mpom/picture-club/pages/dev/emailtemplate.vue?macro=true";
import { default as mediaLLNe4ia4kZMeta } from "/builds/mpom/picture-club/pages/dev/media.vue?macro=true";
import { default as passwordlessU9f8Rhxx1oMeta } from "/builds/mpom/picture-club/pages/dev/passwordless.vue?macro=true";
import { default as qrscanu5tdoxTTahMeta } from "/builds/mpom/picture-club/pages/dev/qrscan.vue?macro=true";
import { default as testROSb3UMs4TMeta } from "/builds/mpom/picture-club/pages/dev/test.vue?macro=true";
import { default as devtoolswmsATgIOHBMeta } from "/builds/mpom/picture-club/pages/devtools.vue?macro=true";
import { default as email_45actionsr00K4UasA7Meta } from "/builds/mpom/picture-club/pages/email-actions.vue?macro=true";
import { default as guestGBmMxKCNdRMeta } from "/builds/mpom/picture-club/pages/guest.vue?macro=true";
import { default as indexHlSzPVCxR0Meta } from "/builds/mpom/picture-club/pages/index.vue?macro=true";
import { default as loginwIrH8EsCiNMeta } from "/builds/mpom/picture-club/pages/login.vue?macro=true";
import { default as logout0KHq6qwqRkMeta } from "/builds/mpom/picture-club/pages/logout.vue?macro=true";
import { default as passwordlessk5knytD5xoMeta } from "/builds/mpom/picture-club/pages/passwordless.vue?macro=true";
import { default as previewMeghVgKQDkMeta } from "/builds/mpom/picture-club/pages/preview.vue?macro=true";
import { default as printable_45qrcodeoIg8anDqKZMeta } from "/builds/mpom/picture-club/pages/printable-qrcode.vue?macro=true";
import { default as privacymXtd36xXRhMeta } from "/builds/mpom/picture-club/pages/privacy.vue?macro=true";
import { default as aboutHKTZkz8ppBMeta } from "/builds/mpom/picture-club/pages/profile/about.vue?macro=true";
import { default as change_45passwordA5qqdVBEpsMeta } from "/builds/mpom/picture-club/pages/profile/change-password.vue?macro=true";
import { default as deleteaccountQn02C8SYHRMeta } from "/builds/mpom/picture-club/pages/profile/deleteaccount.vue?macro=true";
import { default as indexywyYVCPw2qMeta } from "/builds/mpom/picture-club/pages/profile/index.vue?macro=true";
import { default as language_45switchCo08DZ5kzLMeta } from "/builds/mpom/picture-club/pages/profile/language-switch.vue?macro=true";
import { default as profile_45basics2neacz5jgaMeta } from "/builds/mpom/picture-club/pages/profile/profile-basics.vue?macro=true";
import { default as qrscan_45inside6WssjZZsgoMeta } from "/builds/mpom/picture-club/pages/qrscan-inside.vue?macro=true";
import { default as register268JfTwwjCMeta } from "/builds/mpom/picture-club/pages/register.vue?macro=true";
import { default as startEjCstGeTf8Meta } from "/builds/mpom/picture-club/pages/start.vue?macro=true";
export default [
  {
    name: _91id_93AY0Xkf7wEcMeta?.name ?? "camera-roll-id",
    path: _91id_93AY0Xkf7wEcMeta?.path ?? "/camera-roll/:id()",
    meta: _91id_93AY0Xkf7wEcMeta || {},
    alias: _91id_93AY0Xkf7wEcMeta?.alias || [],
    redirect: _91id_93AY0Xkf7wEcMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/camera-roll/[id].vue").then(m => m.default || m)
  },
  {
    name: camera_45rollsz13mQR48myMeta?.name ?? "camera-rolls",
    path: camera_45rollsz13mQR48myMeta?.path ?? "/camera-rolls",
    meta: camera_45rollsz13mQR48myMeta || {},
    alias: camera_45rollsz13mQR48myMeta?.alias || [],
    redirect: camera_45rollsz13mQR48myMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/camera-rolls.vue").then(m => m.default || m)
  },
  {
    name: confirmation_45successBYCGU7MX7xMeta?.name ?? "confirmation-success",
    path: confirmation_45successBYCGU7MX7xMeta?.path ?? "/confirmation-success",
    meta: confirmation_45successBYCGU7MX7xMeta || {},
    alias: confirmation_45successBYCGU7MX7xMeta?.alias || [],
    redirect: confirmation_45successBYCGU7MX7xMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/confirmation-success.vue").then(m => m.default || m)
  },
  {
    name: about2BXZC7DPQUMeta?.name ?? "dev-about",
    path: about2BXZC7DPQUMeta?.path ?? "/dev/about",
    meta: about2BXZC7DPQUMeta || {},
    alias: about2BXZC7DPQUMeta?.alias || [],
    redirect: about2BXZC7DPQUMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/about.vue").then(m => m.default || m)
  },
  {
    name: democdZBOKojfJMeta?.name ?? "dev-demo",
    path: democdZBOKojfJMeta?.path ?? "/dev/demo",
    meta: democdZBOKojfJMeta || {},
    alias: democdZBOKojfJMeta?.alias || [],
    redirect: democdZBOKojfJMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/demo.vue").then(m => m.default || m)
  },
  {
    name: emailtemplatei8yj5TU5IkMeta?.name ?? "dev-emailtemplate",
    path: emailtemplatei8yj5TU5IkMeta?.path ?? "/dev/emailtemplate",
    meta: emailtemplatei8yj5TU5IkMeta || {},
    alias: emailtemplatei8yj5TU5IkMeta?.alias || [],
    redirect: emailtemplatei8yj5TU5IkMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/emailtemplate.vue").then(m => m.default || m)
  },
  {
    name: mediaLLNe4ia4kZMeta?.name ?? "dev-media",
    path: mediaLLNe4ia4kZMeta?.path ?? "/dev/media",
    meta: mediaLLNe4ia4kZMeta || {},
    alias: mediaLLNe4ia4kZMeta?.alias || [],
    redirect: mediaLLNe4ia4kZMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/media.vue").then(m => m.default || m)
  },
  {
    name: passwordlessU9f8Rhxx1oMeta?.name ?? "dev-passwordless",
    path: passwordlessU9f8Rhxx1oMeta?.path ?? "/dev/passwordless",
    meta: passwordlessU9f8Rhxx1oMeta || {},
    alias: passwordlessU9f8Rhxx1oMeta?.alias || [],
    redirect: passwordlessU9f8Rhxx1oMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/passwordless.vue").then(m => m.default || m)
  },
  {
    name: qrscanu5tdoxTTahMeta?.name ?? "dev-qrscan",
    path: qrscanu5tdoxTTahMeta?.path ?? "/dev/qrscan",
    meta: qrscanu5tdoxTTahMeta || {},
    alias: qrscanu5tdoxTTahMeta?.alias || [],
    redirect: qrscanu5tdoxTTahMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/qrscan.vue").then(m => m.default || m)
  },
  {
    name: testROSb3UMs4TMeta?.name ?? "dev-test",
    path: testROSb3UMs4TMeta?.path ?? "/dev/test",
    meta: testROSb3UMs4TMeta || {},
    alias: testROSb3UMs4TMeta?.alias || [],
    redirect: testROSb3UMs4TMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/dev/test.vue").then(m => m.default || m)
  },
  {
    name: devtoolswmsATgIOHBMeta?.name ?? "devtools",
    path: devtoolswmsATgIOHBMeta?.path ?? "/devtools",
    meta: devtoolswmsATgIOHBMeta || {},
    alias: devtoolswmsATgIOHBMeta?.alias || [],
    redirect: devtoolswmsATgIOHBMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/devtools.vue").then(m => m.default || m)
  },
  {
    name: email_45actionsr00K4UasA7Meta?.name ?? "email-actions",
    path: email_45actionsr00K4UasA7Meta?.path ?? "/email-actions",
    meta: email_45actionsr00K4UasA7Meta || {},
    alias: email_45actionsr00K4UasA7Meta?.alias || [],
    redirect: email_45actionsr00K4UasA7Meta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/email-actions.vue").then(m => m.default || m)
  },
  {
    name: guestGBmMxKCNdRMeta?.name ?? "guest",
    path: guestGBmMxKCNdRMeta?.path ?? "/guest",
    meta: guestGBmMxKCNdRMeta || {},
    alias: guestGBmMxKCNdRMeta?.alias || [],
    redirect: guestGBmMxKCNdRMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/guest.vue").then(m => m.default || m)
  },
  {
    name: indexHlSzPVCxR0Meta?.name ?? "index",
    path: indexHlSzPVCxR0Meta?.path ?? "/",
    meta: indexHlSzPVCxR0Meta || {},
    alias: indexHlSzPVCxR0Meta?.alias || [],
    redirect: indexHlSzPVCxR0Meta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginwIrH8EsCiNMeta?.name ?? "login",
    path: loginwIrH8EsCiNMeta?.path ?? "/login",
    meta: loginwIrH8EsCiNMeta || {},
    alias: loginwIrH8EsCiNMeta?.alias || [],
    redirect: loginwIrH8EsCiNMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logout0KHq6qwqRkMeta?.name ?? "logout",
    path: logout0KHq6qwqRkMeta?.path ?? "/logout",
    meta: logout0KHq6qwqRkMeta || {},
    alias: logout0KHq6qwqRkMeta?.alias || [],
    redirect: logout0KHq6qwqRkMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: passwordlessk5knytD5xoMeta?.name ?? "passwordless",
    path: passwordlessk5knytD5xoMeta?.path ?? "/passwordless",
    meta: passwordlessk5knytD5xoMeta || {},
    alias: passwordlessk5knytD5xoMeta?.alias || [],
    redirect: passwordlessk5knytD5xoMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/passwordless.vue").then(m => m.default || m)
  },
  {
    name: previewMeghVgKQDkMeta?.name ?? "preview",
    path: previewMeghVgKQDkMeta?.path ?? "/preview",
    meta: previewMeghVgKQDkMeta || {},
    alias: previewMeghVgKQDkMeta?.alias || [],
    redirect: previewMeghVgKQDkMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: printable_45qrcodeoIg8anDqKZMeta?.name ?? "printable-qrcode",
    path: printable_45qrcodeoIg8anDqKZMeta?.path ?? "/printable-qrcode",
    meta: printable_45qrcodeoIg8anDqKZMeta || {},
    alias: printable_45qrcodeoIg8anDqKZMeta?.alias || [],
    redirect: printable_45qrcodeoIg8anDqKZMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/printable-qrcode.vue").then(m => m.default || m)
  },
  {
    name: privacymXtd36xXRhMeta?.name ?? "privacy",
    path: privacymXtd36xXRhMeta?.path ?? "/privacy",
    meta: privacymXtd36xXRhMeta || {},
    alias: privacymXtd36xXRhMeta?.alias || [],
    redirect: privacymXtd36xXRhMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: aboutHKTZkz8ppBMeta?.name ?? "profile-about",
    path: aboutHKTZkz8ppBMeta?.path ?? "/profile/about",
    meta: aboutHKTZkz8ppBMeta || {},
    alias: aboutHKTZkz8ppBMeta?.alias || [],
    redirect: aboutHKTZkz8ppBMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/profile/about.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordA5qqdVBEpsMeta?.name ?? "profile-change-password",
    path: change_45passwordA5qqdVBEpsMeta?.path ?? "/profile/change-password",
    meta: change_45passwordA5qqdVBEpsMeta || {},
    alias: change_45passwordA5qqdVBEpsMeta?.alias || [],
    redirect: change_45passwordA5qqdVBEpsMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: deleteaccountQn02C8SYHRMeta?.name ?? "profile-deleteaccount",
    path: deleteaccountQn02C8SYHRMeta?.path ?? "/profile/deleteaccount",
    meta: deleteaccountQn02C8SYHRMeta || {},
    alias: deleteaccountQn02C8SYHRMeta?.alias || [],
    redirect: deleteaccountQn02C8SYHRMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/profile/deleteaccount.vue").then(m => m.default || m)
  },
  {
    name: indexywyYVCPw2qMeta?.name ?? "profile",
    path: indexywyYVCPw2qMeta?.path ?? "/profile",
    meta: indexywyYVCPw2qMeta || {},
    alias: indexywyYVCPw2qMeta?.alias || [],
    redirect: indexywyYVCPw2qMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: language_45switchCo08DZ5kzLMeta?.name ?? "profile-language-switch",
    path: language_45switchCo08DZ5kzLMeta?.path ?? "/profile/language-switch",
    meta: language_45switchCo08DZ5kzLMeta || {},
    alias: language_45switchCo08DZ5kzLMeta?.alias || [],
    redirect: language_45switchCo08DZ5kzLMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/profile/language-switch.vue").then(m => m.default || m)
  },
  {
    name: profile_45basics2neacz5jgaMeta?.name ?? "profile-profile-basics",
    path: profile_45basics2neacz5jgaMeta?.path ?? "/profile/profile-basics",
    meta: profile_45basics2neacz5jgaMeta || {},
    alias: profile_45basics2neacz5jgaMeta?.alias || [],
    redirect: profile_45basics2neacz5jgaMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/profile/profile-basics.vue").then(m => m.default || m)
  },
  {
    name: qrscan_45inside6WssjZZsgoMeta?.name ?? "qrscan-inside",
    path: qrscan_45inside6WssjZZsgoMeta?.path ?? "/qrscan-inside",
    meta: qrscan_45inside6WssjZZsgoMeta || {},
    alias: qrscan_45inside6WssjZZsgoMeta?.alias || [],
    redirect: qrscan_45inside6WssjZZsgoMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/qrscan-inside.vue").then(m => m.default || m)
  },
  {
    name: register268JfTwwjCMeta?.name ?? "register",
    path: register268JfTwwjCMeta?.path ?? "/register",
    meta: register268JfTwwjCMeta || {},
    alias: register268JfTwwjCMeta?.alias || [],
    redirect: register268JfTwwjCMeta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/register.vue").then(m => m.default || m)
  },
  {
    name: startEjCstGeTf8Meta?.name ?? "start",
    path: startEjCstGeTf8Meta?.path ?? "/start",
    meta: startEjCstGeTf8Meta || {},
    alias: startEjCstGeTf8Meta?.alias || [],
    redirect: startEjCstGeTf8Meta?.redirect || undefined,
    component: () => import("/builds/mpom/picture-club/pages/start.vue").then(m => m.default || m)
  }
]