export const appHead = {"meta":[{"name":"viewport","content":"viewport-fit=cover, width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"},{"charset":"utf-8"}],"link":[{"rel":"manifest","href":"manifest.webmanifest"},{"rel":"icon","type":"image/webp","href":"/icons/icon-192.webp"}],"style":[],"script":[{"async":true,"crossorigin":"anonymous","type":"module","src":"https://unpkg.com/@ionic/pwa-elements@latest/dist/ionicpwaelements/ionicpwaelements.esm.js"}],"noscript":[],"viewport":"viewport-fit=cover, width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"}

export const appPageTransition = {"mode":"out-in"}

export const appLayoutTransition = {"mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null