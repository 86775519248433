<script setup lang="ts">
const config = useRuntimeConfig()
// @ts-ignore
const isCypress = computed(() => window?.Cypress)
</script>
<template>
  <NuxtLayout>
    <NuxtPage />
    <div
      v-if="config.public.debug && !isCypress"
      class="fixed p-2 bottom-0 w-full text-center text-xs text-stone-400 pointer-events-none"
    >
      <DebugInfo />
    </div>
  </NuxtLayout>
</template>
<style>
/* Default styles for the whole app */
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;500;700&display=swap');

body {
  @apply font-light;
}

/* for slideshow */
body.noscroll {
  overscroll-behavior: none;
}

/* https://stackoverflow.com/questions/28411499/disable-scrolling-on-body */
html.noscroll,
body.noscroll {
  overflow: hidden;
  margin: 0;
  height: 100%;
}

.firebase-emulator-warning {
  visibility: hidden;
}

div {
  /* https://stackoverflow.com/questions/25704650/disable-blue-highlight-when-touch-press-object-with-cursorpointer */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

h2 {
  @apply text-lg font-medium;
}

p {
  @apply my-4;
}

p a {
  @apply underline;
}

a {
  @apply cursor-pointer;
}

.txt-error {
  @apply text-red-500 font-medium text-sm;
}

.Vue-Toastification__container.custom-container-class {
  @apply p-5;
}

.Vue-Toastification__toast.custom-toast-class {
  @apply rounded-xl font-medium;
  font-family: 'Sora', sans-serif;
}

.page-left-enter-active,
.page-right-enter-active,
.page-left-leave-active,
.page-right-leave-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 150ms linear;
}
.page-left-enter-from,
.page-right-leave-to {
  transform: translateX(100%);
}
.page-left-leave-to,
.page-right-enter-from {
  transform: translateX(-100%);
}
.page-left-enter-to,
.page-right-enter-to {
  transform: translateX(0);
}

.page {
  min-height: 100vh;
  width: min(95vw, 700px);
  margin: 0 auto;
  padding: 1rem;
}
</style>
