import plugin_vue3_A0OWXRrUgq from "/builds/mpom/picture-club/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/builds/mpom/picture-club/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/mpom/picture-club/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/mpom/picture-club/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/mpom/picture-club/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/builds/mpom/picture-club/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/mpom/picture-club/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/builds/mpom/picture-club/node_modules/nuxt/dist/app/plugins/payload.client.js";
import deepLinks_QjB9r6Oao0 from "/builds/mpom/picture-club/plugins/deepLinks.ts";
import firebase_client_zXNDa0wxFH from "/builds/mpom/picture-club/plugins/firebase.client.ts";
import global_components_PEc2PKHunz from "/builds/mpom/picture-club/plugins/global-components.ts";
import i18n_VfGcjrvSkj from "/builds/mpom/picture-club/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/builds/mpom/picture-club/plugins/sentry.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  deepLinks_QjB9r6Oao0,
  firebase_client_zXNDa0wxFH,
  global_components_PEc2PKHunz,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk
]